import { AriaModalOverlayProps, Overlay, useModalOverlay } from "react-aria";
import { OverlayTriggerState } from "react-stately";
import { useRef, useState } from "react";
import { IconButton } from "../../components/Buttons/IconButton";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";
import { SecondaryButton } from "../../components/Buttons/SecondaryButton";
import { DiscoveryAdvertiserJobAdvertiserNamesResponse } from "@repo/browser-sdk/dist/api";

export type AdvertiserQuery = {
  page: number;
  take: number;
};

interface Props extends AriaModalOverlayProps {
  state: OverlayTriggerState;
  advertisers: DiscoveryAdvertiserJobAdvertiserNamesResponse | null;
  advertiserName: string | undefined;
  advertiserQuery: AdvertiserQuery;
  loadingAdvertisers: boolean;
  onAdvertiserSelected: (advertiserName: string) => void;
  overlayClose: () => void;
  onAdvertiserQueryChange: (query: AdvertiserQuery) => void;
}

export const AdvertiserNameFilterOverlay = ({ state, ...props }: Props) => {
  const {
    advertisers,
    advertiserQuery,
    loadingAdvertisers,
    onAdvertiserSelected,
    overlayClose,
    onAdvertiserQueryChange
  } = props;
  const ref = useRef(null);
  const { modalProps, underlayProps } = useModalOverlay(props, state, ref);

  const [advertiserName, setAdvertiserName] = useState<string | undefined>(
    props.advertiserName
  );

  const onAdvertiserOptionClick = (newValue: string) => {
    if (newValue === advertiserName) {
      setAdvertiserName(undefined);
      return;
    }
    setAdvertiserName(newValue);
  };

  const nextAdvertiserPage = () => {
    onAdvertiserQueryChange({
      ...advertiserQuery,
      page: advertiserQuery.page + 1
    });
  };

  const previousAdvertiserPage = () => {
    onAdvertiserQueryChange({
      ...advertiserQuery,
      page: advertiserQuery.page - 1
    });
  };

  const handleSelect = () => {
    if (advertiserName) {
      onAdvertiserSelected(advertiserName);
    }
    overlayClose();
  };

  return (
    <Overlay>
      <div
        style={{
          position: "fixed",
          zIndex: 100,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        {...underlayProps}
      >
        <div {...modalProps} ref={ref}>
          <div className="absolute top-0 right-0 bg-bp-lGray w-[450px] h-full">
            <div className="p-4 h-full">
              <h3 className="text-[24px] pb-2 text-bp-purple font-bold text-center">
                Select an Advertiser
              </h3>
              <div className="mx-auto p-4 max-h-[600px] overflow-scroll bg-white border-bp-gray border rounded">
                {advertisers?.items.map(advertiser => (
                  <div className="pt-4" key={advertiser.id}>
                    <input
                      type="radio"
                      checked={advertiser.id === advertiserName}
                      id={advertiser.id}
                      name={advertiser.id}
                      value={advertiser.id}
                      onChange={() => onAdvertiserOptionClick(advertiser.id)}
                    />
                    <label className="pl-4" htmlFor={advertiser.id}>
                      {advertiser.id}
                    </label>
                  </div>
                ))}
              </div>
              <div className="flex flex-row space-x-4 pt-2">
                <IconButton
                  isDisabled={advertiserQuery.page === 0 || loadingAdvertisers}
                  onPress={previousAdvertiserPage}
                >
                  <MdNavigateBefore className="transform text-white text-[14px]" />
                </IconButton>
                <IconButton
                  isDisabled={!advertisers?.hasNextPage || loadingAdvertisers}
                  onPress={nextAdvertiserPage}
                >
                  <MdNavigateNext className="text-white text-[14px]" />
                </IconButton>
              </div>
              <div className="flex flex-row justify-end absolute bottom-0 pb-4 space-x-4">
                <PrimaryButton onPress={handleSelect}>
                  Select Advertiser
                </PrimaryButton>
                <SecondaryButton onPress={overlayClose}>Close</SecondaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Overlay>
  );
};
