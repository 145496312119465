import clsx from "clsx";
import { AriaButtonProps } from "react-aria";
import { Button } from "./Button";

interface Props extends AriaButtonProps {
  isLoading?: boolean;
}

export const PrimaryButton = (props: Props) => {
  const { isDisabled, children } = props;

  return (
    <Button
      {...props}
      className={clsx(
        "min-w-[128px] px-8 py-2",
        isDisabled
          ? "cursor-not-allowed bg-bp-gray text-bp-black"
          : "bg-bp-indigo text-white hover:bg-bp-pink"
      )}
    >
      {children}
    </Button>
  );
};
