import { useEffect, useState } from "react";
import useSourceMappings from "../../hooks/useSourceMappings";
import useDiscoveryJobFeeds from "../../hooks/useDiscoveryJobFeeds";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";
import { DateTime } from "luxon";
import { SecondaryButton } from "../../components/Buttons/SecondaryButton";
import { useNavigate } from "react-router-dom";
import { useOverlayTriggerState } from "react-stately";
import { DefaultModal } from "../../components/Modal/DefaultModal";
import { ModalTitle } from "../../components/Modal/ModalTitle";
import { ModalText } from "../../components/Modal/ModalText";
import { ModalButtons } from "../../components/Modal/ModalButtons";
import { BiTrash } from "react-icons/bi";

const DiscoveryJobFeedList = () => {
  const { sourceMappings, getSourceMappings } = useSourceMappings();
  const { discoveryJobFeeds, getDiscoveryJobFeeds, refreshFeeds, deleteFeed } =
    useDiscoveryJobFeeds();
  const navigate = useNavigate();
  const refreshFeedsDialogState = useOverlayTriggerState({});
  const deleteFeedDialogState = useOverlayTriggerState({});
  const [feedIdToDelete, setFeedIdToDelete] = useState<string | null>(null);

  useEffect(() => {
    getSourceMappings();
  }, [getSourceMappings]);

  useEffect(() => {
    getDiscoveryJobFeeds();
  }, [getDiscoveryJobFeeds]);

  const onAddNewFeeClick = () => {
    navigate("/discoveryJobFeed/add");
  };

  const onViewJobsPress = (feedId: string) => {
    navigate(`/discoveryJobFeed/${feedId}/jobs`);
  };

  const onConfigureFeedPress = (feedId: string) => {
    navigate(`/discoveryJobFeed/${feedId}/configure`);
  };

  const onRefreshFeedPress = (feedId: string) => {
    refreshFeeds([feedId]);
    refreshFeedsDialogState.open();
  };

  const onRefreshModalClose = () => {
    refreshFeedsDialogState.close();
  };

  const onDeletePressed = (feedId: string) => {
    setFeedIdToDelete(feedId);
    deleteFeedDialogState.open();
  };

  const onDeleteConfirmed = () => {
    if (feedIdToDelete) {
      deleteFeed(feedIdToDelete);
    }

    deleteFeedDialogState.close();
    setFeedIdToDelete(null);
    getDiscoveryJobFeeds();
  };

  return (
    <div>
      {refreshFeedsDialogState.isOpen && (
        <DefaultModal state={refreshFeedsDialogState} isDismissable>
          <ModalTitle>Job Feed Refreshing</ModalTitle>
          <ModalText>
            Jobs for this Feed are being parsed and will be ready for viewing
            shortly!
          </ModalText>
          <ModalButtons>
            <SecondaryButton onPress={onRefreshModalClose}>
              Close
            </SecondaryButton>
          </ModalButtons>
        </DefaultModal>
      )}
      {deleteFeedDialogState.isOpen && (
        <DefaultModal state={deleteFeedDialogState} isDismissable>
          <ModalTitle>Delete Feed</ModalTitle>
          <ModalText>
            Are you sure you want to delete this feed? This action cannot be
            undone.
          </ModalText>
          <ModalButtons>
            <SecondaryButton onPress={deleteFeedDialogState.close}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onPress={() => {
                onDeleteConfirmed();
              }}
            >
              Delete
            </PrimaryButton>
          </ModalButtons>
        </DefaultModal>
      )}
      <div className="flex pt-6 pb-8 justify-end">
        <PrimaryButton onPress={onAddNewFeeClick}>Add New Feed</PrimaryButton>
      </div>
      <h2 className="text-[24px] font-bold text-bp-pink mb-8">
        Discovery Job Feed List
      </h2>
      {Object.keys(discoveryJobFeeds).map(key => {
        const matchingSourceMapping = sourceMappings.find(
          sourceMapping => sourceMapping.id === key
        );

        return (
          <div className="pb-6 border-b border-bp-gray" key={key}>
            <h2 className="text-lg font-semibold text-bp-purple mb-4">
              {matchingSourceMapping?.name + " Feeds"}
            </h2>
            {discoveryJobFeeds[key].map(feed => (
              <div className="pb-4" key={feed.id}>
                <div className="pl-8 flex flex-row w-full space-x-6">
                  <h3 className="font-semibold">{feed.name}</h3>
                  <p className="text-sm italic pl-2 pt-[3px] lg:max-w-52 xl:max-w-full md:hidden lg:block truncate overflow-hidden">
                    {feed.lastUpdated &&
                      "Last Updated: " +
                        DateTime.fromJSDate(feed.lastUpdated).toLocaleString(
                          DateTime.DATETIME_FULL_WITH_SECONDS
                        )}
                  </p>
                </div>
                <div className="flex flex-row justify-end space-x-4 -mt-9 pr-8">
                  {feed.sourceMappingId && (
                    <SecondaryButton
                      onPress={() => onRefreshFeedPress(feed.id)}
                    >
                      Refresh Feed
                    </SecondaryButton>
                  )}
                  {feed.sourceMappingId && (
                    <PrimaryButton onPress={() => onViewJobsPress(feed.id)}>
                      View Jobs
                    </PrimaryButton>
                  )}
                  {!feed.sourceMappingId && (
                    <PrimaryButton
                      onPress={() => onConfigureFeedPress(feed.id)}
                    >
                      Configure
                    </PrimaryButton>
                  )}
                  <BiTrash
                    onClick={() => onDeletePressed(feed.id)}
                    className="text-red-500 text-2xl font-bold hover:text-red-700 hover:cursor-pointer mt-2"
                  />
                </div>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};
export default DiscoveryJobFeedList;
