import ApiProvider from "./providers/ApiProvider";
import AuthProvider from "./providers/AuthProvider";
import Routes from "./routes/Routes";

function App() {
  return (
    <AuthProvider>
      <ApiProvider>
        <Routes />
      </ApiProvider>
    </AuthProvider>
  );
}

export default App;
