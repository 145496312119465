import {
  BoostpointProgrammaticMarketingClient,
  BoostpointProgrammaticMarketingEnvironment,
} from "@repo/browser-sdk";
import { createContext, useContext } from "react";

export type BpProgMarketingApi = {
  client: BoostpointProgrammaticMarketingClient;
  ready: boolean;
  environment: BoostpointProgrammaticMarketingEnvironment;
};

export const ApiContext = createContext<BpProgMarketingApi | null>(null);

export const useApi = () => {
  const api = useContext(ApiContext);

  if (!api) {
    throw new Error(`You must call useApi() inside of an <ApiProvider />`);
  }

  return api;
};
