import clsx from "clsx";
import { AriaButtonProps } from "react-aria";
import { Button } from "./Button";

interface Props extends AriaButtonProps {
  isLoading?: boolean;
}

export const SecondaryButton = (props: Props) => {
  const { isDisabled, children } = props;

  return (
    <Button
      {...props}
      className={clsx(
        "min-w-[128px] border-2 bg-transparent px-8 py-2",
        isDisabled
          ? "cursor-not-allowed border-bp-gray text-bp-gray"
          : "border-bp-indigo text-bp-indigo hover:border-bp-pink hover:text-bp-pink"
      )}
    >
      {children}
    </Button>
  );
};
