import {
  DiscoveryJobFeedDtoInterface,
  DiscoveryJobFeedUpsertDtoInterface,
} from "@repo/browser-sdk/dist/api";
import { useCallback, useState } from "react";
import { useApi } from "../providers/ApiProvider";
import { DiscoveryJobFeedDto } from "../models/discoveryJobFeed.dto";

const useDiscoveryJobFeeds = (id?: string) => {
  const [discoveryJobFeeds, setDiscoveryJobFeeds] = useState<
    Record<string, DiscoveryJobFeedDtoInterface[]>
  >({});
  const [discoveryJobFeed, setDiscoveryJobFeed] =
    useState<DiscoveryJobFeedDto | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [schema, setSchema] = useState<string[] | null>(null);

  const { client, ready } = useApi();

  const getDiscoveryJobFeeds = useCallback(async () => {
    if (ready) {
      const feeds = await client.discoveryJobFeed.findAll();
      setDiscoveryJobFeeds(feeds.data);
    }
  }, [client, ready]);

  const getDiscoveryJobFeed = async (): Promise<void> => {
    if (ready && id) {
      const feed = await client.discoveryJobFeed.get(id);

      if (!feed.success || !feed.data) {
        let errorMessage = "Failed to get discovery job feed";

        if (feed.message) {
          errorMessage = feed.message;
        }

        setError(errorMessage);
        throw new Error(errorMessage);
      }

      setDiscoveryJobFeed(new DiscoveryJobFeedDto(feed.data));
    }
  };

  const upsertDiscoveryJobFeed = async (
    requestBody: DiscoveryJobFeedUpsertDtoInterface
  ): Promise<DiscoveryJobFeedDto> => {
    try {
      if (id) {
        requestBody.id = id;
      }
      const newRequest = await client.discoveryJobFeed.post({
        ...discoveryJobFeed,
        ...requestBody,
      });

      if (!newRequest.success || !newRequest.data) {
        let errorMessage = "Failed to create discovery job feed";

        if (newRequest.message) {
          errorMessage = newRequest.message;
        }

        setError(errorMessage);
        throw new Error(errorMessage);
      }

      getDiscoveryJobFeeds();
      return new DiscoveryJobFeedDto(newRequest.data);
    } catch (err) {
      let message = "Unknown error";
      if (err instanceof Error) message = err.message;
      console.log("error getting adding feed", message);
    }
    return new DiscoveryJobFeedDto({});
  };

  const refreshFeeds = async (ids: string[]): Promise<void> => {
    if (ready) {
      await client.discoveryJobFeed.refresh({ ids });
    }
  };

  const deleteFeed = async (feedId: string): Promise<void> => {
    if (ready) {
      await client.discoveryJobFeed.delete(feedId);
    }
  };

  const getSchema = async (): Promise<string[]> => {
    try {
      if (ready && id) {
        const schema = await client.discoveryJobFeed.getSchema(id);

        if (!schema.success || !schema.data) {
          let errorMessage = "Failed to get schema";

          if (schema.message) {
            errorMessage = schema.message;
          }

          setError(errorMessage);
          throw new Error(errorMessage);
        }

        setSchema(schema.data);

        return schema.data;
      }
    } catch (err) {
      console.log("schema error");
      return [];
    }
    return [];
  };

  return {
    discoveryJobFeeds,
    discoveryJobFeed,
    getDiscoveryJobFeed,
    getDiscoveryJobFeeds,
    upsertDiscoveryJobFeed,
    refreshFeeds,
    getSchema,
    schema,
    error,
    deleteFeed,
  };
};

export default useDiscoveryJobFeeds;
