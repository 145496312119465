import { Outlet } from "react-router-dom";
import { Page } from "../../components/Layout/Page";
import { useAuth } from "../../providers/AuthProvider";
import { useEffect, useState } from "react";
import useViewport from "../../hooks/useViewport";

const PageWrapper = () => {
  const { currentUser } = useAuth();
  const { width } = useViewport();

  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (width > 640) setMenuOpen(true);
  }, [width]);

  return (
    <Page
      menuIsOpen={menuOpen}
      toggleMenu={() => setMenuOpen(!menuOpen)}
      user={currentUser}
    >
      <Outlet />
    </Page>
  );
};
export default PageWrapper;
