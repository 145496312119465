import { RefObject, useRef } from "react";
import { AriaListBoxOptions, AriaListBoxProps, useListBox } from "react-aria";
import { ListState, useListState } from "react-stately";
import { ListBoxSection } from "./ListBoxSelection";
import { Option } from "./Option";
import clsx from "clsx";

interface Props<T> {
  listBoxRef?: RefObject<HTMLUListElement>;
  state?: ListState<T>;
  className?: string;
}

type PropsWithChildren<T> = AriaListBoxProps<T> & Props<T>;
type PropsWithNoChildren<T> = AriaListBoxOptions<T> & Props<T>;

export const ListBox = <T extends object>(
  props: PropsWithChildren<T> | PropsWithNoChildren<T>
) => {
  const ref = useRef(null);

  // Create state based on the incoming props
  const defaultState = useListState(props);

  const { listBoxRef = ref, state = defaultState, label, className } = props;

  // Get props for the listbox element
  const { listBoxProps, labelProps } = useListBox(props, state, listBoxRef);

  return (
    <>
      <label {...labelProps}>{label}</label>
      <ul
        {...listBoxProps}
        className={clsx(
          "my-2 max-h-96 list-none overflow-y-scroll rounded-3xl border-[1px] border-solid border-bp-gray bg-white p-0 shadow-lg",
          className
        )}
        ref={listBoxRef}
      >
        {[...state.collection].map(item =>
          item.type === "section" ? (
            <ListBoxSection key={item.key} section={item} state={state} />
          ) : (
            <Option key={item.key} item={item} state={state} />
          )
        )}
      </ul>
    </>
  );
};
