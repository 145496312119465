import { BiLoaderCircle } from "react-icons/bi";

export const Loading = (props: {
  loading: boolean;
  classes?: string;
  smaller?: boolean;
}) => (
  <div
    className={`absolute flex items-center justify-center bg-[#FAFAFA] ${
      props.loading
        ? "pointer-events-auto h-full w-full opacity-100"
        : "pointer-events-none h-0 w-0 opacity-0"
    } transition-all duration-700 ${props.classes ? props.classes : ""}`}
  >
    <BiLoaderCircle
      className={`${
        props.smaller ? "text-xl" : "text-6xl"
      } -mt-5 animate-spin text-gray-300`}
    />
  </div>
);
