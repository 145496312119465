import { AriaButtonProps } from "react-aria";
import { Button } from "./Button";
import clsx from "clsx";

type Props = AriaButtonProps;

export const IconButton = (props: Props) => {
  const { isDisabled, children } = props;

  return (
    <Button
      {...props}
      className={clsx(
        "stroke flex max-h-[24px] items-center justify-center rounded-full border-2   p-1 text-xs",
        isDisabled
          ? "cursor-not-allowed bg-bp-gray text-bp-black"
          : "border-bp-indigo hover:bg-bp-pink hover:cursor-pointer bg-bp-indigo hover:border-bp-pink"
      )}
    >
      {children}
    </Button>
  );
};
