import { BiLeftArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { TertiaryButton } from "../components/Buttons/TertiaryButton";

const NotFound = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center md:pb-[200px]">
      <p className="mt-8 flex flex-row items-center gap-1 text-bp-purple">
        <BiLeftArrowAlt fontSize="22px" className="hidden md:flex" /> We
        couldn't find that page.
      </p>
      <p className="mt-6 text-center">
        <TertiaryButton>
          <Link to="/home">Home</Link>
        </TertiaryButton>
      </p>
    </div>
  );
};

export default NotFound;
