import React, { useEffect, useState } from "react";
import {
  BoostpointProgrammaticMarketingClient,
  BoostpointProgrammaticMarketingEnvironment,
} from "@repo/browser-sdk";
import { useAuth } from "../AuthProvider";
import { ApiContext } from "./context";

const ApiProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;

  const [client, setClient] = useState<BoostpointProgrammaticMarketingClient>(
    new BoostpointProgrammaticMarketingClient({
      environment: BoostpointProgrammaticMarketingEnvironment.Development,
      token: "",
    })
  );
  const [ready, setReady] = useState(false);
  const mode = import.meta.env.MODE;
  const { getToken, currentUser } = useAuth();
  let environment: BoostpointProgrammaticMarketingEnvironment;

  switch (mode) {
    case "production":
      environment = BoostpointProgrammaticMarketingEnvironment.Production;
      break;
    case "staging":
      environment = BoostpointProgrammaticMarketingEnvironment.Staging;
      break;
    default:
      environment = BoostpointProgrammaticMarketingEnvironment.Development;
  }

  useEffect(() => {
    if (currentUser?.uid) {
      const c = new BoostpointProgrammaticMarketingClient({
        environment,
        token: async () => {
          return await getToken();
        },
      });
      setClient(c);
      setReady(true);
    } else {
      setReady(false);
    }
  }, [environment, currentUser?.uid, getToken]);

  const wrapped = {
    client,
    ready,
    environment,
  };

  return <ApiContext.Provider value={wrapped}>{children}</ApiContext.Provider>;
};

export default ApiProvider;
