import { useEffect, useState } from "react";
import useDiscoveryJobFeeds from "../../hooks/useDiscoveryJobFeeds";
import useSourceMappings from "../../hooks/useSourceMappings";
import { Select } from "../../components/InputElements/Select";
import { Item, Key, useOverlayTriggerState } from "react-stately";
import { TextField } from "../../components/InputElements/TextField";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";
import { SecondaryButton } from "../../components/Buttons/SecondaryButton";
import { useNavigate } from "react-router-dom";
import { DefaultModal } from "../../components/Modal/DefaultModal";
import { ModalTitle } from "../../components/Modal/ModalTitle";
import { ModalText } from "../../components/Modal/ModalText";
import { ModalButtons } from "../../components/Modal/ModalButtons";

const DiscoveryJobFeedAdd = () => {
  const { sourceMappings, getSourceMappings } = useSourceMappings();
  const {
    upsertDiscoveryJobFeed,
    refreshFeeds,
    error: discoveryJobFeedError
  } = useDiscoveryJobFeeds();
  const navigate = useNavigate();

  const [sourceMappingId, setSourceMappingId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [jobFeedUrl, setJobFeedUrl] = useState<string>("");
  const successDialogState = useOverlayTriggerState({});

  useEffect(() => {
    getSourceMappings();
  }, [getSourceMappings]);

  const handleSelectionChange = (key: Key) => {
    if (sourceMappingId === key) {
      setSourceMappingId("");
      return;
    }
    setSourceMappingId(key as string);
  };

  const handleSave = async () => {
    let newSourceMappingId;

    if (sourceMappingId !== "") {
      newSourceMappingId = sourceMappingId;
    }

    if (name && jobFeedUrl) {
      const result = await upsertDiscoveryJobFeed({
        sourceMappingId: newSourceMappingId,
        name,
        jobFeedUrl
      });
      if (result.sourceMappingId) {
        await refreshFeeds([result.id]);
        successDialogState.open();
        return;
      }

      // need to configure a new Source Mapping
      navigate(`/discoveryJobFeed/${result.id}/configure`);
    }
  };

  const goBack = () => {
    navigate("/home");
  };

  const onModalClose = () => {
    successDialogState.close();
    navigate("/home");
  };

  const sourceMappingOptions = sourceMappings.map(sourceMapping => ({
    id: sourceMapping.id,
    name: sourceMapping.name
  }));

  sourceMappingOptions.push({
    id: "",
    name: "New Source Mapping"
  });

  return (
    <div>
      <div className="text-left pb-12">
        <div
          onClick={goBack}
          className="-ml-12 text-bp-pink hover:underline hover:cursor-pointer"
        >
          {"← Go back"}
        </div>
      </div>
      <div className="text-center">
        <div className="mx-auto w-1/2 h-full">
          <h2 className="text-[24px] font-bold text-bp-purple">
            Add New Job Feed
          </h2>
          <form className="text-left">
            <TextField
              label="Feed Name"
              aria-label="feed name"
              id="feedname"
              type="text"
              value={name}
              name="name"
              onChange={setName}
              className="pt-8"
            />
            <TextField
              label="Job Feed URL"
              aria-label="job feed url"
              id="jobfeedurl"
              type="text"
              value={jobFeedUrl}
              name="name"
              onChange={setJobFeedUrl}
              className="pt-6 pb-6"
            />
            <Select
              label="Select Source Mapping"
              selectedKey={sourceMappingId}
              onSelectionChange={handleSelectionChange}
              items={sourceMappingOptions}
            >
              {item => <Item>{item.name}</Item>}
            </Select>
            <div
              className={`absolute bottom-0 left-0 flex w-full flex-row items-center justify-center bg-bp-pink p-2 ${
                !discoveryJobFeedError ? "max-h-0 opacity-0" : "max-h-40"
              } w-full transition-all duration-500`}
            >
              <p className="font-bold text-white ">{discoveryJobFeedError}</p>
            </div>
            <div className="pt-12 flex justify-center space-x-12">
              <PrimaryButton
                isDisabled={!name || !jobFeedUrl}
                onPress={handleSave}
              >
                Save
              </PrimaryButton>
              <SecondaryButton onPress={goBack}>Cancel</SecondaryButton>
            </div>
          </form>
          {successDialogState.isOpen && (
            <DefaultModal state={successDialogState} isDismissable>
              <ModalTitle>Mapping Successfully Configured</ModalTitle>
              <ModalText>
                Jobs for this Feed are being parsed and will be ready for
                viewing shortly!
              </ModalText>
              <ModalButtons>
                <SecondaryButton onPress={onModalClose}>Close</SecondaryButton>
              </ModalButtons>
            </DefaultModal>
          )}
        </div>
      </div>
    </div>
  );
};
export default DiscoveryJobFeedAdd;
