import { ReactNode } from "react";
import { AriaModalOverlayProps } from "react-aria";
import { OverlayTriggerState } from "react-stately";
import { ModalBody } from "./ModalBody";
import { Modal } from "./Modal";
import { ModalDialog } from "./ModalDialog";

interface Props extends AriaModalOverlayProps {
  state: OverlayTriggerState;
  children: ReactNode;
}

export const DefaultModal = ({ state, children, ...props }: Props) => {
  return (
    <Modal state={state} isDismissable {...props}>
      <ModalDialog>
        <ModalBody>{children}</ModalBody>
      </ModalDialog>
    </Modal>
  );
};
