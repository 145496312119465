import { useEffect, useState } from "react";
import { Item } from "react-stately";
import { Key } from "react-aria";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";
import { Select } from "../../components/InputElements/Select";
import { TextField } from "../../components/InputElements/TextField";
import {
  DiscoveryAdvertiserJobFeedQuery,
  DiscoveryAdvertiserJobCpaRangeEnum
} from "@repo/browser-sdk/dist/api";

type FilterProps = {
  query: DiscoveryAdvertiserJobFeedQuery;
  setQuery: (query: DiscoveryAdvertiserJobFeedQuery) => void;
  onApplyFilters: () => void;
};

const cpaRangeTypeOptions = [
  { id: DiscoveryAdvertiserJobCpaRangeEnum.Gte, name: "Greater Than" },
  { id: DiscoveryAdvertiserJobCpaRangeEnum.Lte, name: "Less Than" },
  { id: DiscoveryAdvertiserJobCpaRangeEnum.Between, name: "Range" }
];

const DiscoveryJobFilters = (props: FilterProps) => {
  const { query, setQuery, onApplyFilters } = props;

  const [tempQuery, setTempQuery] =
    useState<DiscoveryAdvertiserJobFeedQuery>(query);

  useEffect(() => {
    setTempQuery(query);
  }, [query]);

  const handleCpaRangeTypeSelectionChange = (key: Key) => {
    if (key === DiscoveryAdvertiserJobCpaRangeEnum.Between) {
      setTempQuery({
        ...tempQuery,
        cpaRangeType: key as DiscoveryAdvertiserJobCpaRangeEnum,
        cpaType: "range"
      });
      return;
    } else if (
      tempQuery.cpaRangeType === (key as DiscoveryAdvertiserJobCpaRangeEnum)
    ) {
      setTempQuery({
        ...tempQuery,
        cpaRangeType: undefined,
        cpaType: undefined
      });
      return;
    }
    setTempQuery({
      ...tempQuery,
      cpaRangeType: key as DiscoveryAdvertiserJobCpaRangeEnum,
      cpaType: "value"
    });
  };

  const handleApply = () => {
    setQuery(tempQuery);
    onApplyFilters();
  };

  return (
    <div className="float-left border border-bp-lgray bg-bp-lgray mb-12 p-4">
      <h3 className="text-[18px] font-semibold text-bp-purple text-left pb-4">
        Filters
      </h3>
      <div className="flex flex-row text-left space-x-12">
        <div className="min-w-[275px]">
          <TextField
            label="Title Search"
            aria-label="title search"
            id="title-search"
            type="text"
            name="title"
            value={tempQuery.title}
            onChange={title => setTempQuery({ ...tempQuery, title })}
          />
        </div>
        <div className="min-w-[275px]">
          <div className="pb-4">
            <Select
              aria-label="CPA Range"
              label="CPA Range"
              selectedKey={tempQuery.cpaRangeType ?? ""}
              onSelectionChange={handleCpaRangeTypeSelectionChange}
              items={cpaRangeTypeOptions}
            >
              {item => <Item>{item.name}</Item>}
            </Select>
          </div>
        </div>
        <div className="min-w-[275px]">
          {tempQuery.cpaType === "value" && (
            <TextField
              label="CPA Value"
              aria-label="cpa value"
              id="cpa-value"
              type="number"
              name="cpa"
              value={tempQuery.value?.toString() ?? ""}
              onChange={(value: string) =>
                setTempQuery({
                  ...tempQuery,
                  value: Number(value) || undefined
                })
              }
            />
          )}
          {tempQuery.cpaType === "range" && (
            <div className="flex flex-col space-y-4">
              <TextField
                label="Lower Value"
                aria-label="lower value"
                id="lower-value"
                type="number"
                name="lowerValue"
                value={tempQuery.lowerValue?.toString() ?? ""}
                onChange={(lowerValue: string) =>
                  setTempQuery({
                    ...tempQuery,
                    lowerValue: Number(lowerValue) || undefined
                  })
                }
              />
              <TextField
                label="Upper Value"
                aria-label="upper value"
                id="upper-value"
                type="number"
                name="upperValue"
                value={tempQuery.upperValue?.toString() ?? ""}
                onChange={(upperValue: string) =>
                  setTempQuery({
                    ...tempQuery,
                    upperValue: Number(upperValue) || undefined
                  })
                }
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-row justify-end pt-12 space-x-4">
        <PrimaryButton onPress={handleApply}>Apply & Close</PrimaryButton>
      </div>
    </div>
  );
};

export default DiscoveryJobFilters;
