import * as Sentry from "@sentry/react";
import {
  Auth,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  User
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { AuthContext, BpProgMarketingAuth } from "./context";
import { FirebaseError } from "firebase/app";

const AuthProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [auth, _] = useState<Auth>(getAuth());
  const [authError, setAuthError] = useState("");
  const [authMessage, setAuthMessage] = useState("");
  const [authLoading, setAuthLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<User | null>(auth.currentUser);

  const logOut = async () => {
    if (auth) {
      await auth.signOut();
    }
    resetMessages();
    setCurrentUser(null);
    Sentry.setUser(null);
  };

  useEffect(() => {
    onAuthStateChanged(auth, async fbUser => {
      if (fbUser && fbUser?.uid !== currentUser?.uid) {
        const firebaseToken = await fbUser.getIdTokenResult();
        if (firebaseToken.claims.admin) {
          Sentry.setUser({
            id: fbUser.uid,
            name: fbUser.displayName || undefined,
            email: fbUser.email || undefined
          });
          resetMessages();
          setCurrentUser(fbUser);
        } else {
          console.log("The current user does not have admin permissions");
          setAuthError("The current user does not have admin permissions");
        }
      }
      setAuthLoading(false);
    });
  }, [auth, currentUser?.uid]);

  const logIn = async (userEmail: string, password: string) => {
    resetMessages();
    if (auth) {
      try {
        await signInWithEmailAndPassword(auth, userEmail, password);
      } catch (e: unknown) {
        if (!(e instanceof FirebaseError)) return;

        if (
          e.code === "auth/wrong-password" ||
          e.code === "auth/user-not-found"
        ) {
          setAuthError("Invalid login");
        } else if (e.code === "auth/invalid-email") {
          setAuthError("Invalid email");
        } else {
          setAuthError(`Unknown login error: ${e.message}`);
        }
      }
    }
  };

  const resetMessages = () => {
    setAuthError("");
    setAuthMessage("");
  };

  const getToken = async () => {
    if (auth.currentUser) {
      return await auth.currentUser.getIdToken();
    } else return undefined;
  };

  const refreshToken = async () => {
    if (auth.currentUser) {
      await auth.currentUser.getIdToken(true);
    } else return undefined;
  };

  const wrapped: BpProgMarketingAuth = {
    ...auth,
    currentUser,
    logIn,
    logOut,
    authError,
    authLoading,
    authMessage,
    resetMessages,
    getToken,
    refreshToken
  };

  return (
    <AuthContext.Provider value={wrapped}>{children}</AuthContext.Provider>
  );
};
export default AuthProvider;
