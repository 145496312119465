import { createBrowserRouter, redirect } from "react-router-dom";
import Login from "../../pages/Login/Login";

export const unauthenticatedRoutes = createBrowserRouter([
  {
    path: "/",
    loader: async () => {
      return redirect("/login");
    }
  },
  {
    path: "login",
    element: <Login />
  },
  {
    path: "*",
    element: <Login />
  }
]);
