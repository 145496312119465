import { ReactNode, useRef } from "react";
import type { AriaDialogProps } from "react-aria";
import { useDialog } from "react-aria";

interface Props extends AriaDialogProps {
  title?: ReactNode;
  children: ReactNode;
}

export const ModalDialog = ({ title, children, ...props }: Props) => {
  const ref = useRef(null);
  const { dialogProps, titleProps } = useDialog(props, ref);

  return (
    <div
      {...dialogProps}
      ref={ref}
      className="min-w-[768px] flex-col rounded-2xl bg-white bg-no-repeat p-4 pt-0 text-[16px] backdrop:backdrop-blur-lg md:w-[50%] md:max-w-[775px]"
    >
      {title && (
        <h3 {...titleProps} className="mt-6 text-2xl font-semibold">
          {title}
        </h3>
      )}
      {children}
    </div>
  );
};
