import { Link } from "react-router-dom";
import { TextButton } from "../Buttons/TextButton";
import { User } from "firebase/auth";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  user?: User;
};

export const UserSlideout = (props: Props) => {
  const { open, setOpen, user } = props;

  return (
    <div
      className={`${
        !open && "hidden"
      } border-bg-gray absolute right-[25px] top-[94px] z-30 h-[175px] w-[280px] overflow-hidden rounded-2xl border-[2px] border-solid bg-white`}
    >
      <div className="flex h-full flex-col p-8">
        <div className="mb-4 flex flex-row gap-4">
          <div className="flex h-[40px] min-w-[40px] items-center justify-center rounded-full border-[1px] border-solid border-bp-dkGray text-[20px] font-semibold md:h-[50px] md:min-w-[50px]">
            {`${user?.displayName?.charAt(0).toUpperCase() || ""}${
              user?.displayName?.split(" ")[1].charAt(0).toUpperCase() || ""
            }`}
          </div>
          <div className="flex flex-col items-start justify-center text-[14px]">
            <div className="cursor-pointer font-medium">
              {`${user?.displayName || ""}`}{" "}
            </div>
          </div>
        </div>
        <div className="flex grow-[2] flex-col items-start justify-between pt-4">
          <Link to="/logout" onClick={() => setOpen(false)}>
            <TextButton>Logout</TextButton>
          </Link>
        </div>
      </div>
    </div>
  );
};
