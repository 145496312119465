import clsx from "clsx";
import { MutableRefObject, ReactNode, useRef } from "react";
import { AriaButtonProps, useButton } from "react-aria";
import { SpinningLoader } from "../Loading/SpinningLoader";

interface Props extends AriaButtonProps {
  buttonRef?: MutableRefObject<HTMLButtonElement | null>;
  className?: string;
  loaderClassName?: string;
  isLoading?: boolean;
  title?: string;
  children: ReactNode;
}

export const Button = (props: Props) => {
  const ref = useRef(null);

  const {
    buttonRef = ref,
    className,
    title,
    loaderClassName,
    isLoading,
    children
  } = props;
  const { buttonProps } = useButton(props, buttonRef);

  if (isLoading) {
    buttonProps.disabled = true;
  }

  return (
    <button
      {...buttonProps}
      title={title}
      ref={buttonRef}
      className={clsx(
        "flex flex-row items-center justify-center rounded-full font-medium focus:outline-none",
        className
      )}
    >
      {isLoading && (
        <SpinningLoader className={clsx("text-xl", loaderClassName)} />
      )}
      {!isLoading && children}
    </button>
  );
};
