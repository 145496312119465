import clsx from "clsx";
import { BiLoaderCircle } from "react-icons/bi";

interface Props {
  className?: string;
}

export const SpinningLoader = (props: Props) => {
  const { className } = props;

  return <BiLoaderCircle className={clsx("animate-spin-slower", className)} />;
};
