import { ReactNode, useRef } from "react";
import {
  AriaPopoverProps,
  DismissButton,
  Overlay,
  usePopover
} from "react-aria";
import { OverlayTriggerState } from "react-stately";

interface Props extends Omit<AriaPopoverProps, "popoverRef"> {
  fullWidth?: boolean;
  offset?: number;
  isScrollable?: boolean;
  children?: ReactNode;
  state: OverlayTriggerState;
}

export const Popover = (props: Props) => {
  const { fullWidth = false, offset = 8, children, state } = props;

  const popoverRef = useRef<HTMLDivElement>(null);
  const { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      offset,
      popoverRef
    },
    state
  );

  return (
    <Overlay>
      <div
        {...underlayProps}
        style={{ position: "fixed", inset: 0 }}
        className="underlay"
      />
      <div
        {...popoverProps}
        ref={popoverRef}
        style={{
          ...popoverProps.style,
          width: fullWidth ? props.triggerRef.current?.clientWidth : "auto"
        }}
        className="popover"
      >
        <DismissButton onDismiss={state.close} />
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
};
