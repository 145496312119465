import {
  SourceMappingDto,
  SourceMappingUpsertDto
} from "@repo/browser-sdk/dist/api";
import { useCallback, useState } from "react";
import { useApi } from "../providers/ApiProvider";

const useSourceMappings = () => {
  const { client, ready } = useApi();

  const [error, setError] = useState<string | null>(null);
  const [sourceMappings, setSourceMappings] = useState<SourceMappingDto[]>([]);

  const getSourceMappings = useCallback(async () => {
    if (ready) {
      const mappings = await client.sourceMapping.findAll();

      if (!mappings.success || !mappings.data) {
        let errorMessage = "Failed to get source mappings";

        if (mappings.message) {
          errorMessage = mappings.message;
        }

        setError(errorMessage);
        return;
      }

      setSourceMappings(mappings.data);
    }
  }, [client, ready]);

  const upsertSourceMapping = async (
    requestBody: SourceMappingUpsertDto
  ): Promise<SourceMappingDto | null> => {
    const newRequest = await client.sourceMapping.post(requestBody);

    if (!newRequest.success || !newRequest.data) {
      let errorMessage = "Failed to create source mapping";

      if (newRequest.message) {
        errorMessage = newRequest.message;
      }

      setError(errorMessage);
      return null;
    }

    return newRequest.data;
  };

  return {
    sourceMappings,
    getSourceMappings,
    upsertSourceMapping,
    error
  };
};

export default useSourceMappings;
