/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import useDiscoveryJobFeeds from "../../hooks/useDiscoveryJobFeeds";
import { useEffect, useMemo, useState } from "react";
import { Select } from "../../components/InputElements/Select";
import { Item, Key, useOverlayTriggerState } from "react-stately";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";
import { SecondaryButton } from "../../components/Buttons/SecondaryButton";
import { TextField } from "../../components/InputElements/TextField";
import useSourceMappings from "../../hooks/useSourceMappings";
import { DefaultModal } from "../../components/Modal/DefaultModal";
import { ModalTitle } from "../../components/Modal/ModalTitle";
import { ModalText } from "../../components/Modal/ModalText";
import { ModalButtons } from "../../components/Modal/ModalButtons";

const DiscoveryJobFeedConfigure = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const {
    schema,
    getDiscoveryJobFeed,
    getSchema,
    discoveryJobFeed,
    upsertDiscoveryJobFeed,
    refreshFeeds,
    error: discoveryJobFeedError
  } = useDiscoveryJobFeeds(id);
  const { upsertSourceMapping, error: sourceMappingError } =
    useSourceMappings();
  const successDialogState = useOverlayTriggerState({});

  const [name, setName] = useState<string>("");
  const [data, setData] = useState<[string, string][]>([
    ["", "address"],
    ["", "advertiser"],
    ["", "advertiserJobId"],
    ["", "category"],
    ["", "city"],
    ["", "company"],
    ["", "country"],
    ["", "cpa"],
    ["", "date"],
    ["", "description"],
    ["", "postalCode"],
    ["", "postbackUrl"],
    ["", "questions"],
    ["", "requisiteId"],
    ["", "state"],
    ["", "title"],
    ["", "url"]
  ]);

  const getAvailableSchemaOptions = () => {
    let options = schema?.map(field => ({ id: field, name: field }));
    data.forEach(([source]) => {
      if (source) {
        options = options?.filter(option => option.id !== source);
      }
    });

    return options;
  };

  let schemaOptions: { id: string; name: string }[] = useMemo(
    () => getAvailableSchemaOptions() ?? [],
    [schema, data]
  ).sort((a, b) => a.name.localeCompare(b.name));

  const handleSelectionChange = (key: Key, destination: string) => {
    const newData = data.map(([source, value]) => {
      if (value === destination) {
        if (source === key) {
          schemaOptions.push({ id: source, name: source });
          return ["", value] as [string, string];
        }

        schemaOptions = schemaOptions.filter(e => e.id === source);
        return [key as string, value] as [string, string];
      }
      return [source, value] as [string, string];
    });
    setData(newData);
  };

  const getSelectOptions = (key: Key) => {
    const options = [...schemaOptions];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const dataKey = data.find(([_, destination]) => destination === key);

    if (dataKey) {
      options.push({ id: dataKey[0], name: dataKey[0] });
    }

    return options;
  };

  const handleSave = async () => {
    if (!schema || !name || !discoveryJobFeed) {
      return;
    }
    const result = await upsertSourceMapping({
      name: name,
      schema: schema,
      mapping: data
    });

    if (!result) {
      return;
    }

    await upsertDiscoveryJobFeed({
      id: discoveryJobFeed.id,
      sourceMappingId: result.id
    });

    await refreshFeeds([discoveryJobFeed.id]);

    successDialogState.open();
  };

  const goBack = () => {
    navigate("/home");
  };

  const onModalClose = () => {
    successDialogState.close();
    navigate("/home");
  };

  useEffect(() => {
    getData();
  }, [id]);

  const getData = async () => {
    await getSchema();
    await getDiscoveryJobFeed();
  };

  return (
    <div>
      <div className="text-center">
        <div className="mx-auto w-full h-full">
          <h2 className="text-[24px] font-bold text-bp-purple pb-12">
            Job Feed Field Mapping
          </h2>
          <div className=" w-1/2 mx-auto pb-12">
            <TextField
              label="Source Mapping Name"
              aria-label="Source Mapping Name"
              id="name"
              type="text"
              value={name}
              onChange={setName}
              className="pt-8"
            />
          </div>
          <div className="w-full h-full flex flex-row">
            <h2 className="w-1/2 text-lg font-semibold text-bp-pink pt-3 pb-3 border-b border-r border-bp-gray">
              Example Record
            </h2>
            <h2 className="w-1/2 text-lg font-semibold text-bp-pink pt-3 pb-3 border-b border-bp-gray">
              Field Mapping
            </h2>
          </div>
          <div className="w-full h-full flex flex-row">
            <div className="w-1/2 border-r border-bp-gray mr-6 text-left pr-6">
              {discoveryJobFeed?.firstRecord != null &&
                Object.entries(discoveryJobFeed.firstRecord).map(
                  ([key, value]) => (
                    <div
                      key={`first-record-${key}`}
                      className="flex flex-row border-b border-bp-gray pt-6 pb-6 lg:min-h-[99px] md:min-h-[123px]"
                    >
                      <p className="w-5/12 text-lg font-semibold text-bp-pink pt-3">
                        {key}:
                      </p>
                      <p className="w-7/12 text-lg text-bp-black pt-3 truncate">
                        {value}
                      </p>
                    </div>
                  )
                )}
            </div>
            <form className="text-left w-2/3">
              {data.map(([source, destination]) => (
                <div
                  className="flex flex-row border-b border-bp-gray pt-6 pb-6"
                  key={`${source}-${destination}`}
                >
                  <p className="w-1/2 text-lg font-semibold text-bp-pink pt-3 capitalize">
                    {destination}:
                  </p>
                  <div className="w-1/2">
                    <Select
                      aria-label={`Select ${destination} Source Field`}
                      selectedKey={source ?? ""}
                      onSelectionChange={(key: Key) =>
                        handleSelectionChange(key, destination)
                      }
                      items={[...getSelectOptions(destination)]}
                    >
                      {item => <Item textValue={item.name}>{item.name}</Item>}
                    </Select>
                  </div>
                </div>
              ))}
            </form>
          </div>
          <div
            className={`absolute bottom-0 left-0 flex w-full flex-row items-center justify-center bg-bp-pink p-2 ${
              !discoveryJobFeedError && !sourceMappingError
                ? "max-h-0 opacity-0"
                : "max-h-40"
            } w-full transition-all duration-500`}
          >
            <p className="font-bold text-white ">
              {discoveryJobFeedError || sourceMappingError}
            </p>
          </div>
          <div className="w-full flex justify-center space-x-12 pt-12">
            <PrimaryButton isDisabled={!name} onPress={handleSave}>
              Save
            </PrimaryButton>
            <SecondaryButton onPress={goBack}>Cancel</SecondaryButton>
          </div>
          {successDialogState.isOpen && (
            <DefaultModal state={successDialogState} isDismissable>
              <ModalTitle>Mapping Successfully Configured</ModalTitle>
              <ModalText>
                Jobs for this Feed are being parsed and will be ready for
                viewing shortly!
              </ModalText>
              <ModalButtons>
                <SecondaryButton onPress={onModalClose}>Close</SecondaryButton>
              </ModalButtons>
            </DefaultModal>
          )}
        </div>
      </div>
    </div>
  );
};
export default DiscoveryJobFeedConfigure;
