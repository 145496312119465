import { AriaButtonProps } from "react-aria";
import { Button } from "./Button";
import clsx from "clsx";

interface Props extends AriaButtonProps {
  isLoading?: boolean;
  className?: string;
}

export const TextButton = (props: Props) => {
  const { isDisabled, children, className } = props;

  return (
    <Button
      {...props}
      className={clsx(
        isDisabled
          ? "cursor-not-allowed text-bp-gray"
          : "text-bp-indigo hover:text-bp-pink",
        className
      )}
    >
      {children}
    </Button>
  );
};
