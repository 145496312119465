import clsx from "clsx";
import { useState } from "react";
import { AriaTextFieldProps } from "react-aria";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { TextField } from "./TextField";
import { ToggleButton } from "../Buttons/ToggleButton";

interface Props extends AriaTextFieldProps {
  showPassword?: boolean;
  className?: string;
}

export const PasswordField = (props: Props) => {
  const { showPassword = false, className, isDisabled } = props;

  const [_showPassword, setShowPassword] = useState(showPassword);

  return (
    <TextField
      {...props}
      className={className}
      button={
        <ToggleButton
          isDisabled={isDisabled}
          aria-label="view password"
          onChange={setShowPassword}
          className={clsx("m-0 p-0", isDisabled && "text-bp-gray")}
        >
          {_showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
        </ToggleButton>
      }
      type={_showPassword ? "text" : "password"}
      autoComplete="new-password"
    />
  );
};
