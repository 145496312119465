import { Auth, User } from "firebase/auth";
import { createContext, useContext } from "react";

export type BpProgMarketingAuth = Auth & {
  currentUser: User | null;
  logIn(email: string, password: string): Promise<void>;
  logOut(): Promise<void>;
  authError: string;
  authLoading: boolean;
  authMessage: string;
  resetMessages: () => void;
  getToken: () => Promise<string | undefined>;
  refreshToken: () => Promise<void>;
};

export const AuthContext = createContext<BpProgMarketingAuth | null>(null);

export const useAuth = () => {
  const auth = useContext(AuthContext);
  if (!auth) {
    throw new Error(`You must call useAuth() inside of an <AuthProvider />`);
  }
  return auth;
};
