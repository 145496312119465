import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { initializeApp } from "firebase/app";
import * as Sentry from "@sentry/react";
import { BoostpointProgrammaticMarketingEnvironment } from "@repo/browser-sdk";

const firebaseConfig = {
  apiKey: "AIzaSyA7uUJ3q2du0YcLSJEQuWHozUxmbLCePl8",
  authDomain: "auth.boostpoint.com",
  databaseURL: "https://campaign-191617.firebaseio.com",
  projectId: "campaign-191617",
  storageBucket: "campaign-191617.appspot.com",
};
initializeApp(firebaseConfig);

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: APP_VERSION,
  environment: import.meta.env.NODE_ENV,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        BoostpointProgrammaticMarketingEnvironment.Development.discovery,
        BoostpointProgrammaticMarketingEnvironment.Staging.discovery,
        BoostpointProgrammaticMarketingEnvironment.Production.discovery,
        BoostpointProgrammaticMarketingEnvironment.Development.jobFeedProcessor,
        BoostpointProgrammaticMarketingEnvironment.Staging.jobFeedProcessor,
        BoostpointProgrammaticMarketingEnvironment.Production.jobFeedProcessor,
      ],
    }),
    Sentry.browserTracingIntegration({}),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
