import { useParams } from "react-router-dom";
import useDiscoveryAdvertiserJobs from "../../hooks/useDiscoveryAdvertiserJobs";
import { useCallback, useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import { useDebouncedCallback } from "use-debounce";
import { FaFilter, FaSort, FaSortUp } from "react-icons/fa";
import {
  DiscoveryAdvertiserJobFeedQuery,
  DiscoveryAdvertiserJobSortByDirection,
  DiscoveryAdvertiserJobSortByType
} from "@repo/browser-sdk/dist/api";
import { Select } from "../../components/InputElements/Select";
import { Key } from "react-aria";
import { Item, useOverlayTriggerState } from "react-stately";
import { IconButton } from "../../components/Buttons/IconButton";
import { MdClear, MdNavigateNext } from "react-icons/md";
import { TextButton } from "../../components/Buttons/TextButton";
import {
  AdvertiserNameFilterOverlay,
  AdvertiserQuery
} from "./AdvertiserNameFilterOverlay";
import DiscoveryJobFilters from "./DiscoveryJobFilters";
import { Loading } from "../../components/Loading/Loading";

const DiscoveryAdvertiserJobList = () => {
  const { id } = useParams<{ id: string }>();

  const [advertiserQuery, setAdvertiserQuery] = useState<AdvertiserQuery>({
    page: 0,
    take: 20
  });

  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const advertiserOverlayState = useOverlayTriggerState({});

  const [query, setQuery] = useState<DiscoveryAdvertiserJobFeedQuery>({
    page: 0,
    take: 20,
    discoveryJobFeedId: id ?? "",
    title: ""
  });

  const tableRef = useRef<HTMLDivElement>(null);

  const [hasFilters, setHasFilters] = useState<boolean>(false);

  const takeOptions = [
    { id: 20, name: "20" },
    { id: 50, name: "50" },
    { id: 100, name: "100" }
  ];

  const {
    discoveryAdvertiserJobs,
    advertisers,
    getDiscoveryAdvertiserJobs,
    getAdvertiserNames,
    loadingJobs,
    loadingAdvertisers
  } = useDiscoveryAdvertiserJobs({ query });

  const onHeaderClick = (type: DiscoveryAdvertiserJobSortByType) => {
    if (query.sortByType === type) {
      if (query.sortByDirection === DiscoveryAdvertiserJobSortByDirection.Asc) {
        setQuery({
          ...query,
          sortByDirection: DiscoveryAdvertiserJobSortByDirection.Desc
        });
      } else if (
        query.sortByDirection === DiscoveryAdvertiserJobSortByDirection.Desc
      ) {
        setQuery({
          ...query,
          sortByDirection: undefined,
          sortByType: undefined
        });
      } else {
        setQuery({
          ...query,
          sortByDirection: DiscoveryAdvertiserJobSortByDirection.Asc
        });
      }
    } else {
      setQuery({
        ...query,
        sortByType: type,
        sortByDirection: DiscoveryAdvertiserJobSortByDirection.Asc
      });
    }
  };

  const fetchJobs = useCallback(() => {
    getDiscoveryAdvertiserJobs();
  }, [getDiscoveryAdvertiserJobs]);

  const fetchAdvertisers = useCallback(() => {
    getAdvertiserNames(advertiserQuery.page, 20);
  }, [advertiserQuery, getAdvertiserNames]);

  const filterIconClick = () => {
    setOpenFilters(!openFilters);
  };

  const onApplyFilters = () => {
    setOpenFilters(false);
  };

  const onClearFilters = () => {
    setQuery({
      ...query,
      title: "",
      cpaRangeType: undefined,
      cpaType: undefined,
      lowerValue: undefined,
      upperValue: undefined,
      value: undefined,
      advertiserName: undefined
    });
    setAdvertiserQuery({
      ...advertiserQuery,
      page: 0
    });
    fetchJobs();
    setOpenFilters(false);
  };

  const debouncedFetchJobs = useDebouncedCallback(fetchJobs, 500);

  useEffect(() => {
    debouncedFetchJobs();
  }, [query, debouncedFetchJobs]);

  useEffect(() => {
    if (
      query.title ||
      (query.cpaType &&
        ((query.cpaType === "range" && query.lowerValue && query.upperValue) ||
          (query.cpaType === "value" && query.value)))
    ) {
      setHasFilters(true);
      tableRef.current?.scrollTo(0, 0);
    } else {
      setHasFilters(false);
    }
    fetchAdvertisers();
  }, [
    query.title,
    query.cpaRangeType,
    query.cpaType,
    query.lowerValue,
    query.upperValue,
    query.value,
    fetchAdvertisers
  ]);

  const openAdvertiserOverlay = () => {
    advertiserOverlayState.open();
  };

  const onAdvertiserSelected = (advertiserName: string) => {
    setQuery({ ...query, advertiserName: advertiserName });
  };

  const nextPage = () => {
    setQuery({ ...query, page: query.page + 1 });
  };

  const previousPage = () => {
    setQuery({ ...query, page: query.page - 1 });
  };

  const clearAdvertiserName = () => {
    setQuery({ ...query, advertiserName: undefined });
  };

  return (
    <div className="text-center">
      {advertiserOverlayState.isOpen && (
        <AdvertiserNameFilterOverlay
          state={advertiserOverlayState}
          isDismissable
          advertisers={advertisers}
          advertiserQuery={advertiserQuery}
          loadingAdvertisers={loadingAdvertisers}
          onAdvertiserSelected={onAdvertiserSelected}
          overlayClose={advertiserOverlayState.close}
          advertiserName={query.advertiserName}
          onAdvertiserQueryChange={setAdvertiserQuery}
        />
      )}
      {!loadingJobs && (
        <div>
          <div className="flex flex-row pb-4 justify-between">
            <div className="flex flex-row">
              <h2 className="text-[24px] text-left font-bold text-bp-purple pb-2">
                Discovery Jobs
              </h2>
              {query.advertiserName && (
                <div className="flex flex-row bg-bp-gray border-bp-dkGray border rounded pt-2.5 pr-3 px-2 pb-0 ml-4">
                  <MdClear
                    className="mt-0.5 mr-1 hover:cursor-pointer"
                    onClick={clearAdvertiserName}
                  />
                  <span className="text-sm">{query.advertiserName}</span>
                </div>
              )}
            </div>
            <div className="flex flex-row">
              <div className="flex flex-row mt-2 space-x-4">
                <div className="flex flex-row space-x-4">
                  <div className="mt-0.5">
                    <TextButton
                      onPress={openAdvertiserOverlay}
                      className="underline text-sm"
                    >
                      Filter By Advertiser
                    </TextButton>
                  </div>
                  {hasFilters && (
                    <div className="mt-0.5">
                      <TextButton
                        className="underline text-sm"
                        onPress={onClearFilters}
                      >
                        Clear Filters
                      </TextButton>
                    </div>
                  )}
                </div>
                <div className="w-[24px] h-[24px] bg-bp-indigo bp-4 rounded hover:cursor-pointer hover:bg-bp-pink">
                  <FaFilter
                    onClick={filterIconClick}
                    className="text-white p-1 text-[24px]"
                  ></FaFilter>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row z-1 space-x-4">
            {openFilters && (
              <DiscoveryJobFilters
                query={query}
                setQuery={setQuery}
                onApplyFilters={onApplyFilters}
              />
            )}
          </div>
          <div
            ref={tableRef}
            className="h-[450px] border-separate overflow-scroll rounded-xl border border-solid flex flex-col"
          >
            <table className="w-full overflow-scroll table-auto">
              <thead className="text-bp-purple sticky bg-bp-gray top-0 text-left border-b border-bp-gray">
                <tr>
                  <th className="p-4 pr-8">
                    <div
                      onClick={() =>
                        onHeaderClick(
                          DiscoveryAdvertiserJobSortByType.AdvertiserName
                        )
                      }
                      className="flex flex-row space-x-1 h-full hover:cursor-pointer hover:text-bp-pink"
                    >
                      <div className="h-full">Advertiser</div>{" "}
                      {query.sortByType ===
                        DiscoveryAdvertiserJobSortByType.AdvertiserName && (
                        <FaSortUp
                          className={`mt-1 ${
                            query.sortByDirection ===
                            DiscoveryAdvertiserJobSortByDirection.Asc
                              ? ""
                              : "rotate-180"
                          }`}
                        />
                      )}
                      {!query.sortByType ||
                      query.sortByType !==
                        DiscoveryAdvertiserJobSortByType.AdvertiserName ? (
                        <FaSort className="mt-1" />
                      ) : (
                        ""
                      )}
                    </div>
                  </th>
                  <th className="p-4 pr-8">
                    <div
                      onClick={() =>
                        onHeaderClick(DiscoveryAdvertiserJobSortByType.Company)
                      }
                      className="flex flex-row space-x-1 h-full hover:cursor-pointer hover:text-bp-pink"
                    >
                      <div className="h-full">Company</div>{" "}
                      {query.sortByType ===
                        DiscoveryAdvertiserJobSortByType.Company && (
                        <FaSortUp
                          className={`mt-1 ${
                            query.sortByDirection ===
                            DiscoveryAdvertiserJobSortByDirection.Asc
                              ? ""
                              : "rotate-180"
                          }`}
                        />
                      )}
                      {!query.sortByType ||
                      query.sortByType !==
                        DiscoveryAdvertiserJobSortByType.Company ? (
                        <FaSort className="mt-1" />
                      ) : (
                        ""
                      )}
                    </div>
                  </th>
                  <th className="p-4 pr-8">Title</th>
                  <th className="p-4 pr-8">Category</th>
                  <th className="p-4 pr-8">
                    <div
                      onClick={() =>
                        onHeaderClick(DiscoveryAdvertiserJobSortByType.Cpa)
                      }
                      className="flex flex-row space-x-1 h-full hover:cursor-pointer hover:text-bp-pink"
                    >
                      <div className="h-full">CPA</div>{" "}
                      {query.sortByType ===
                        DiscoveryAdvertiserJobSortByType.Cpa && (
                        <FaSortUp
                          className={`mt-1 ${
                            query.sortByDirection ===
                            DiscoveryAdvertiserJobSortByDirection.Asc
                              ? ""
                              : "rotate-180"
                          }`}
                        />
                      )}
                      {!query.sortByType ||
                      query.sortByType !==
                        DiscoveryAdvertiserJobSortByType.Cpa ? (
                        <FaSort className="mt-1" />
                      ) : (
                        ""
                      )}
                    </div>
                  </th>
                  <th className="p-4 pr-8">Postback URL</th>
                  <th className="p-4 pr-8">Questions</th>
                  <th className="p-4 pr-8">Address</th>
                  <th className="p-4 pr-8">City</th>
                  <th className="p-4 pr-8">State</th>
                  <th className="p-4 pr-8">Postal Code</th>
                  <th className="p-4 pr-8">Country</th>
                  <th className="p-4 pr-8">URL</th>
                  <th className="p-4 pr-8">Date</th>
                  <th className="p-4 pr-8">Advertiser Job ID</th>
                  <th className="p-4 pr-8">Requisite ID</th>
                </tr>
              </thead>
              <tbody className="text-left">
                {discoveryAdvertiserJobs?.items.map(job => (
                  <tr className="border-b border-bp-lgray" key={job.id}>
                    <td className="p-4 pr-8">{job.advertiser ?? "N/A"}</td>
                    <td className="p-4 pr-8">{job.company ?? "N/A"}</td>
                    <td className="p-4 pr-8">{job.title ?? "N/A"}</td>
                    <td className="p-4 pr-8">
                      {(job.category != null && job.category) ?? "N/A"}
                    </td>
                    <td className="p-4 pr-8">{job.cpa ?? "N/A"}</td>
                    <td className="p-4 pr-8">
                      {(job.postbackUrl && (
                        <a
                          className="truncate underline text-blue-500"
                          href={job.postbackUrl}
                          target="_blank"
                        >
                          Open in New Tab
                        </a>
                      )) ??
                        "N/A"}
                    </td>
                    <td className="p-4 pr-8 line-clamp-4">
                      {job.questions ?? "N/A"}
                    </td>
                    <td className="p-4 pr-8">{job.address ?? "N/A"}</td>
                    <td className="p-4 pr-8">{job.city ?? "N/A"}</td>
                    <td className="p-4 pr-8">{job.state ?? "N/A"}</td>
                    <td className="p-4 pr-8">{job.postalCode ?? "N/A"}</td>
                    <td className="p-4 pr-8">{job.country ?? "N/A"}</td>
                    <td className="p-4 pr-8">
                      {(job.url && (
                        <a
                          className="truncate underline text-blue-500"
                          href={job.url}
                          target="_blank"
                        >
                          Open in New Tab
                        </a>
                      )) ??
                        "N/A"}
                    </td>
                    <td className="p-4 pr-8">
                      {job.date &&
                        DateTime.fromJSDate(job.date).toLocaleString(
                          DateTime.DATE_SHORT
                        )}
                    </td>
                    <td className="p-4 pr-8">{job.advertiserJobId ?? "N/A"}</td>
                    <td className="p-4 pr-8">{job.requisiteId ?? "N/A"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex flex-row justify-between mt-4">
            <div className="flex flex-row space-x-4 mt-4">
              <IconButton isDisabled={query.page === 0} onPress={previousPage}>
                <MdNavigateNext className="transform rotate-180 text-white text-[14px]" />
              </IconButton>
              <IconButton
                isDisabled={!discoveryAdvertiserJobs?.hasNextPage}
                onPress={nextPage}
              >
                <MdNavigateNext className="text-white text-[14px]" />
              </IconButton>
              <div className="flex flex-row justify-between pb-4">
                {discoveryAdvertiserJobs && (
                  <div className="italic text-sm text-bp-black mt-0.5">
                    <p>
                      Showing results{" "}
                      <b className="font-semibold">
                        {discoveryAdvertiserJobs.take *
                          discoveryAdvertiserJobs.page +
                          1}
                        -
                        {discoveryAdvertiserJobs?.take *
                          (discoveryAdvertiserJobs?.page + 1)}{" "}
                        of {discoveryAdvertiserJobs?.totalCount} jobs
                      </b>
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div>
              <Select
                aria-label="Take"
                selectedKey={query.take}
                onSelectionChange={(key: Key) =>
                  setQuery({ ...query, take: Number(key) })
                }
                items={takeOptions}
              >
                {item => <Item>{item.name}</Item>}
              </Select>
            </div>
          </div>
        </div>
      )}
      {loadingJobs && (
        <div className="relative top-72">
          <Loading loading={loadingJobs} />
        </div>
      )}
    </div>
  );
};

export default DiscoveryAdvertiserJobList;
