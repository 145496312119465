import {
  DiscoveryJobFeedDtoInterface,
  DiscoveryJobFeedJobFeedSubType,
  DiscoveryJobFeedJobFeedType
} from "@repo/browser-sdk/dist/api";

export class DiscoveryJobFeedDto implements DiscoveryJobFeedDtoInterface {
  id = "";
  name = "";
  sourceMappingId?: string | undefined;
  jobFeedUrl = "";
  jobFeedType: DiscoveryJobFeedJobFeedType = DiscoveryJobFeedJobFeedType.Xml;
  jobFeedSubType: DiscoveryJobFeedJobFeedSubType =
    DiscoveryJobFeedJobFeedSubType.TextXml;
  firstRecord?: Record<string, string> | undefined;
  lastUpdated?: Date | undefined;
  isRunning?: boolean | undefined;
  errorMessage?: string | undefined;

  constructor(partial?: Partial<DiscoveryJobFeedDtoInterface>) {
    if (partial?.id !== undefined) {
      this.id = partial.id;
    }
    if (partial?.name !== undefined) {
      this.name = partial.name;
    }
    if (partial?.sourceMappingId !== undefined) {
      this.sourceMappingId = partial.sourceMappingId;
    }
    if (partial?.jobFeedUrl !== undefined) {
      this.jobFeedUrl = partial.jobFeedUrl;
    }
    if (partial?.jobFeedType !== undefined) {
      this.jobFeedType = partial.jobFeedType;
    }
    if (partial?.jobFeedSubType !== undefined) {
      this.jobFeedSubType = partial.jobFeedSubType;
    }
    if (partial?.firstRecord !== undefined) {
      this.firstRecord = partial.firstRecord as Record<string, string>;
    }
    if (partial?.lastUpdated !== undefined) {
      this.lastUpdated = partial.lastUpdated;
    }
    if (partial?.isRunning !== undefined) {
      this.isRunning = partial.isRunning;
    }
    if (partial?.errorMessage !== undefined) {
      this.errorMessage = partial.errorMessage;
    }
  }
}
