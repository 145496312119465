import { createBrowserRouter, Navigate, redirect } from "react-router-dom";
import DiscoveryJobFeedList from "../../pages/DiscoveryJobFeedList/DiscoveryJobFeedList";
import PageWrapper from "./PageWrapper";
import { Loading } from "../../components/Loading/Loading";
import DiscoveryJobFeedAdd from "../../pages/DiscoveryJobFeedAdd/DiscoveryJobFeedAdd";
import DiscoveryJobFeedConfigure from "../../pages/DiscoveryJobFeedConfigure/DiscoveryJobFeedConfigure";
import DiscoveryAdvertiserJobList from "../../pages/DiscoveryAdvertiserJobList/DiscoveryAdvertiserJobList";
import NotFound from "../../pages/NotFound";

export const authenticatedRouter = (logOut: () => Promise<void>) =>
  createBrowserRouter([
    {
      path: "/",
      loader: async () => {
        return redirect("/home");
      }
    },
    {
      path: "/login",
      element: <Navigate to="/home" />
    },
    {
      path: "logout",
      element: <Loading loading={true} />,
      loader: async () => {
        await logOut();
        return redirect("/");
      }
    },
    { path: "404", element: <NotFound /> },
    {
      path: "*",
      element: <Navigate to="/404" />
    },
    {
      element: <PageWrapper />,
      children: [
        {
          path: "/home",
          element: <DiscoveryJobFeedList></DiscoveryJobFeedList>
        },
        {
          path: "discoveryJobFeed/add",
          element: <DiscoveryJobFeedAdd></DiscoveryJobFeedAdd>
        },
        {
          path: "discoveryJobFeed/:id/configure",
          element: <DiscoveryJobFeedConfigure></DiscoveryJobFeedConfigure>
        },
        {
          path: "discoveryJobFeed/:id/jobs",
          element: <DiscoveryAdvertiserJobList></DiscoveryAdvertiserJobList>
        }
      ]
    }
  ]);
