import { useAuth } from "../../providers/AuthProvider";
import logo from "../../assets/lightLogo.svg";
import { useMemo, useState } from "react";
import { TextField } from "../../components/InputElements/TextField";
import { PasswordField } from "../../components/InputElements/PasswordField";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";

const Login = () => {
  const { logIn, authError } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const disableSubmit = useMemo(() => {
    return !email || !password;
  }, [email, password]);

  const submit = (email: string, password: string) => {
    logIn(email, password);
  };

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!disableSubmit) {
      submit(email, password);
    }
  };

  const onSubmit = () => {
    if (!disableSubmit) {
      submit(email, password);
    }
  };

  return (
    <div className="bg-gradient-to-b from-bp-indigo from-10% via-bp-purple via-60% to-bp-pink absolute w-[100%] h-[100%]">
      <div className="relative z-10 bg-contain bg-[-50px_center] bg-no-repeat xl:bg-left">
        <div className="absolute z-10 hidden xl:block">
          <img
            src={logo}
            alt="Boostpoint"
            className="relative left-[34px] top-[25px] "
          />
        </div>
        <div className="z-10 flex h-[100dvh] flex-col gap-8 overflow-y-scroll px-[5px] xl:flex-row xl:justify-center xl:gap-[100px] xl:px-0 xl:pt-[30px]">
          <div className="p-8 pb-1 xl:hidden">
            <img src={logo} alt="Boostpoint" />
          </div>
          <div className="flex flex-col items-center xl:pb-[30px]">
            <div className="m-auto flex flex-col items-center justify-between gap-2">
              <div className="relative overflow-hidden rounded-2xl bg-white p-8 text-bp-purple md:border-[2px] md:border-solid md:border-bp-gray md:p-16">
                <h1 className="-mt-[18px] mb-4 flex flex-col self-center text-center text-[1.7em] font-semibold md:mb-8 md:items-end">
                  <span className="hidden whitespace-nowrap md:block">
                    Boostpoint&reg;{" "}
                    <span className="text-bp-pink">
                      {" "}
                      Programmatic Marketing
                    </span>
                  </span>
                </h1>
                <form onSubmit={onFormSubmit}>
                  <TextField
                    label="Email"
                    aria-label="username"
                    id="username"
                    type="email"
                    value={email}
                    name="email"
                    onChange={setEmail}
                  />
                  <PasswordField
                    label="Password"
                    value={password}
                    type="password"
                    name="password"
                    onChange={setPassword}
                    className="mt-4"
                  />
                  <div className="mt-8 flex flex-row-reverse items-center justify-center gap-6">
                    <PrimaryButton
                      type="submit"
                      isDisabled={disableSubmit}
                      onPress={onSubmit}
                    >
                      Log In
                    </PrimaryButton>
                  </div>
                </form>
                <div
                  className={`absolute bottom-0 left-0 flex w-full flex-row items-center justify-center bg-bp-pink p-2 ${
                    !authError ? "max-h-0 opacity-0" : "max-h-40"
                  } w-full transition-all duration-500`}
                >
                  <p className="font-bold text-white ">{authError}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
