import { useEffect, useState } from "react";
import { useAuth } from "../providers/AuthProvider";
import { unauthenticatedRoutes } from "./components/UnauthenticatedRoutes";
import { authenticatedRouter } from "./components/AuthenticatedRoutes";
import { RouterProvider } from "react-router-dom";

const Routes = () => {
  const { currentUser, authLoading, logOut } = useAuth();

  const [router, setRouter] = useState(unauthenticatedRoutes);

  useEffect(() => {
    if (currentUser) {
      setRouter(authenticatedRouter(logOut));
    } else {
      setRouter(unauthenticatedRoutes);
    }
  }, [currentUser, logOut]);

  if (authLoading) return <div>Loading...</div>;
  return <RouterProvider router={router} />;
};
export default Routes;
